<template>
  <fragment>
    <h2>Activity</h2>

    <div class="row">
      <div class="col mb-3">
        <label class="form-label">Date From</label>
        <div class="input-group input-group-blend">
          <span class="input-group-text">
            <i class="fas fa-calendar-day"></i>
          </span>
          <c-date v-model="dateFrom" allow-input class="form-control date-range-duo"/>
        </div>
      </div>

      <div class="col-auto">
        <p class="form-control-plaintext mt-label">-</p>
      </div>

      <div class="col">
        <label class="form-label">Date To</label>
        <div class="input-group input-group-blend">
          <span class="input-group-text">
            <i class="fas fa-calendar-day"></i>
          </span>
          <c-date v-model="dateTo" allow-input class="form-control date-range-duo"/>
        </div>
      </div>

      <div class="col-auto">
        <c-button class="btn-primary mt-label" @click="handleGenerateReport" target="_blank">Download</c-button>
      </div>
    </div>

  </fragment>
</template>

<script>
import { http } from '@/utils'
import dayjs from 'dayjs'
import { toast } from '@chameleon/chameleon-vue'

export default {

  data () {
    return {
      dateFrom: null,
      dateTo: null,
    }
  },

  created () {
    this.dateFrom = dayjs().subtract(30, 'day').format('YYYY-MM-DD')
    this.dateTo = dayjs().format('YYYY-MM-DD')
  },

  methods: {
    updateDateRangeValues (from, to) {
      this.dateFrom = from
      this.dateTo = to
    },

    async handleGenerateReport () {
      const params = {
        date_from: this.dateFrom,
        date_to: this.dateTo
      }
      try {
        const response = await http.get('reports/activity', { responseType: 'blob', headers: { accept: 'application/json, text/csv' }, params })
        const startDate = dayjs(this.dateFrom).format('DD-MM-YYYY')
        const endDate = dayjs(this.dateTo).format('DD-MM-YYYY')
        const now = dayjs().format('DD-MM-YYYY-HH-mm')

        const filename = `medcpd-activity-report-${startDate}-${endDate}-${now}.csv`
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        return response
      } catch (error) {
        if (error.response?.status === 404) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
