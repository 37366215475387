<template>
  <div class="row">
    <div class="col d-flex align-items-center">
      <h2 class="m-0">Training Provider Summary</h2>
    </div>
    <div class="col-auto d-flex align-items-center">
      <c-button class="btn btn-primary" @click="handleGenerateReport" target="_blank">
        Download
      </c-button>
    </div>
  </div>
</template>

<script>
import { http } from '@/utils'
import dayjs from 'dayjs'
import { toast } from '@chameleon/chameleon-vue'

export default {

  methods: {
    async handleGenerateReport () {
      try {
        const params = {}
        const response = await http.get('reports/provider', { responseType: 'blob', headers: { accept: 'application/json, text/csv' }, params })

        const now = dayjs().format('DD-MM-YYYY-HH-mm')
        const filename = `medcpd-training-provider-summary-report-${now}.csv`
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        return response
      } catch (error) {
        if (error.response?.status === 404) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
