<template>
  <fragment>
    <app-header title="Reports"/>

    <div class="container">
      <div class="card">
        <div class="card-body">
          <div v-if="$isA('admin')">
            <activity-report/>
            <hr class="my-4">
            <training-provider-summary-report/>
            <hr class="my-4">
            <training-provider-account-performance-report/>
            <hr class="my-4">
            <user-report />
          </div>
          <div v-if="$isA('provider')">
            <training-provider-summary-report/>
            <hr class="my-4">
            <training-provider-account-performance-report/>
            <hr class="my-4">
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import ActivityReport from '@/views/reports/ActivityReport'
import TrainingProviderSummaryReport from '@/views/reports/TrainingProviderSummaryReport'
import UserReport from '@/views/reports/UserReport'
import TrainingProviderAccountPerformanceReport from './TrainingProviderAccountPerformanceReport.vue'

export default {
  components: {
    ActivityReport,
    TrainingProviderSummaryReport,
    UserReport,
    TrainingProviderAccountPerformanceReport
  }
}
</script>
